import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo/seo"
import Banner from "../../components/banner/banner"
import Links from "../../components/services/links/links"

export default function Crime({ data }) {
  return (
    <Layout>
      <SEO title="Criminal Law" />
      <Banner
        image={
          <Img
            fluid={data.placeholderImage.childImageSharp.fluid}
            style={{ height: "100%" }}
          />
        }
        title="Services"
      />
      <div className="width75">
        <h1 className="title">Criminal Law</h1>
        <Links type="about" />
        <div className="ribbon-top">
          <p>
            Criminal allegations made against a person can be very disturbing,
            stressful and difficult to manage without experienced legal
            representation. At JJ Law Chambers we advise and represent clients
            at Police Stations, Magistrates and Crown Courts.
          </p>
          <p>
            We provide a 24 HOUR emergency service for those who have been
            arrested and are held in detention at a police station or those
            facing court attendances on the weekends. We will provide prompt
            help, advice and attendance from the outset of your case.
          </p>
          <p>
            JJ Law Chambers deal with both private paying clients and those
            whose matters will be publicly funded by Legal Aid. Our reputation
            in this area is built on working hard for our clients and ensuring
            that no stone is left unturned in challenging a prosecution case and
            building a solid defence. Our criminal lawyers forcefully defend our
            clients, both against the Police and the CPS.
          </p>

          <p>
            Legal aid may be available to you but this is dependant on your
            means and income. If you are arrested by the police or attend the
            police station voluntarily you will be entitled to free legal
            representation at the police station, providing the interview is
            conducted by a police officer.
          </p>
          <p>
            If you are facing criminal investigation at a Police Station,
            proceedings at the Magistrates or Crown court, need advice on an
            appeal or are released under investigation contact our experienced
            team who can assist you with:
          </p>
          <div className="center">
            <ul>
              <li>Police station interviews</li>
              <li>Court appearance</li>
              <li>Assaults, ABH & GBH</li>
              <li>Benefit Fraud</li>
              <li>Fraud & Dishonesty</li>
              <li>Money Laundering</li>
              <li>Murder & Manslaughter</li>
              <li>Terrorism</li>
              <li>Organised Crime</li>
              <li>Sexual Offences</li>
              <li>Theft & Robbery</li>
              <li>Firearm Offences</li>
              <li>Motoring & Driving</li>
            </ul>
          </div>
          <Img
            fluid={data.bottomImage.childImageSharp.fluid}
            style={{ height: "150px" }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "tower-bridge.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bottomImage: file(relativePath: { eq: "lawers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
